import React, { useState } from "react";

const App = () => {
  const [startHour, setStartHour] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [endMinute, setEndMinute] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);

  const handleReset = () => {
    setStartHour(0);
    setStartMinute(0);
    setEndHour(0);
    setEndMinute(0);
    setElapsedTime(0);
  };

  const calculateElapsedTime = () => {
    let startTime = startHour * 60 + startMinute;
    let endTime = endHour * 60 + endMinute;

    if (endTime < startTime) {
      endTime += 24 * 60;
    }

    const elapsedMinutes = endTime - startTime;
    setElapsedTime(elapsedMinutes);
  };

  const handleInputClick = (e) => {
    if (e.target.value === "0") {
      e.target.value = "";
    }
  };

  const handleChange = (type, value, unit) => {
    if (
      (unit === "hour" && value <= 24) ||
      (unit === "minute" && value <= 60)
    ) {
      if (type === "start") {
        if (unit === "hour") {
          setStartHour(value);
        } else if (unit === "minute") {
          setStartMinute(value);
        }
      } else if (type === "end") {
        if (unit === "hour") {
          setEndHour(value);
        } else if (unit === "minute") {
          setEndMinute(value);
        }
      }
    }
  };

  return (
    <div className="container">
      <h2>Idő számoló</h2>

      <div className="time-input-container">
        <label htmlFor="startHour">Kezdés (óra):</label>
        <input
          type="number"
          id="startHour"
          min={0}
          max={24}
          value={startHour}
          onChange={(e) =>
            handleChange("start", parseInt(e.target.value), "hour")
          }
          onClick={handleInputClick}
        />
      </div>

      <div className="time-input-container">
        <label htmlFor="startMinute">Kezdés (perc):</label>
        <input
          type="number"
          id="startMinute"
          min={0}
          max={60}
          value={startMinute}
          onChange={(e) =>
            handleChange("start", parseInt(e.target.value), "minute")
          }
          onClick={handleInputClick}
        />
      </div>

      <div className="time-input-container">
        <label htmlFor="endHour">Befejezés (óra):</label>
        <input
          type="number"
          id="endHour"
          min={0}
          max={24}
          value={endHour}
          onChange={(e) =>
            handleChange("end", parseInt(e.target.value), "hour")
          }
          onClick={handleInputClick}
        />
      </div>

      <div className="time-input-container">
        <label htmlFor="endMinute">Befejezés (perc):</label>
        <input
          type="number"
          id="endMinute"
          min={0}
          max={60}
          value={endMinute}
          onChange={(e) =>
            handleChange("end", parseInt(e.target.value), "minute")
          }
          onClick={handleInputClick}
        />
      </div>

      <div className="buttons-container">
        <button className="calculate-button" onClick={calculateElapsedTime}>
          Számolás
        </button>
        <button className="reset-button" onClick={handleReset}>
          Törlés
        </button>
      </div>

      <p>Eltelt idő percekben: {elapsedTime}</p>
    </div>
  );
};

export default App;
